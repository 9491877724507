import { Button } from "reactstrap";
import styled from "styled-components";

export const PillButton = styled(Button)`
  cursor: pointer;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  cursor: default;
  padding: 5px 20px;
  background-color: #a2a8b5;

  &:hover {
    background-color: #323643;
  }

  &:active {
    background-color: #edeef0;
  }

  &.active {
    cursor: pointer;
    background-color: #edeef0 !important;

    &:hover {
      background-color: #edeef0 !important;
    }
  }
`;
