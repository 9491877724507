import {
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { create, Dropin, PaymentMethodPayload } from "braintree-web-drop-in";
import { fetchPaymentToken } from "../lib/api";
import { ThreeDSecureParams } from "../model/ThreeDSecure";
import { googlePayMerchantId } from "../lib/constants";

interface Props {
  nonceCreated: (nonce: string, deviceData: string) => void;
  isValidChanged: (isValid: boolean) => void;
  isLoadingChanged: (isLoading: boolean) => void;
  threeDSParams: ThreeDSecureParams;
}

export type BraintreeWrapperRef = {
  startPayment: () => void;
};

export const BraintreeWrapper = forwardRef<BraintreeWrapperRef, Props>(
  (props, ref) => {
    const dropinRef = useRef<HTMLDivElement>(null);
    const [dropin, setDropin] = useState<Dropin>();

    useEffect(() => {
      const setupDropin = async () => {
        props.isLoadingChanged(true);
        try {
          const tokenResponse = await fetchPaymentToken();
          const created = await create({
            authorization: tokenResponse.data.token,
            container: dropinRef.current!,
            threeDSecure: true,
            locale: "de_DE",
            translations: {
              chooseAWayToPay: "",
            },
            paypal: {
              flow: "checkout",
              amount: props.threeDSParams.amount,
              currency: "EUR",
            },
            dataCollector: true,
            // types require some apple pay properties that are not really needed
            applePay: {
              displayName: "Movemates",
              paymentRequest: {
                total: {
                  label: "Movemates",
                  amount: props.threeDSParams.amount,
                },
              } as any,
            },
            googlePay: {
              googlePayVersion: 2 as any,
              merchantId: googlePayMerchantId,
              transactionInfo: {
                totalPriceStatus: "FINAL",
                totalPrice: props.threeDSParams.amount,
                currencyCode: "EUR",
              },
            },
          });
          console.log(created);
          setDropin(created);
          created.on("paymentMethodRequestable", () => {
            props.isValidChanged(true);
          });
          created.on("noPaymentMethodRequestable", () => {
            props.isValidChanged(false);
          });
        } catch (error) {
          console.error(error);
        } finally {
          props.isLoadingChanged(false);
        }
      };
      setupDropin();
    }, []);

    const handlePayClicked = async () => {
      try {
        const method = (await dropin?.requestPaymentMethod({
          threeDSecure: props.threeDSParams,
        } as any)) as unknown as PaymentMethodPayload;
        console.log(method);
        if (method?.nonce) {
          props.nonceCreated(method.nonce, method.deviceData || "");
        }
      } catch (error) {
        console.error(error);
      }
    };

    useImperativeHandle(ref, () => ({
      startPayment: handlePayClicked,
    }));

    return <div ref={dropinRef}></div>;
  }
);
