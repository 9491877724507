import { Component } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import classNames from "classnames";
import Spinner from "react-spinkit";
import "./AddressSuggestionList.scss";

export default class AddressSuggestionList extends Component {
  render() {
    return (
      <div className="row address-list">
        <div className="col-12">
          {this.props.isLoading ? (
            <div style={{ textAlign: "center", margin: "15px" }}>
              <Spinner name="line-scale" color="#a2a8b5" fadeIn="quarter" />
            </div>
          ) : this.props.hasValidSearchQuery &&
            this.props.suggestions.length === 0 ? (
            <div className="text-center">
              <br />
              <span className="no-results">
                Leider konnten wir keine passende Adresse finden.
              </span>
            </div>
          ) : (
            <ListGroup>
              {this.props.suggestions.map((suggestion, index) => {
                return (
                  <ListGroupItem
                    className="address-cell"
                    tag="button"
                    key={suggestion.id}
                    onClick={() => this.props.onAddressClick(suggestion)}
                    action={true}
                  >
                    <div>
                      <p>{suggestion.title}</p>
                      <div
                        className={classNames({
                          separator: true,
                          "d-none": index === this.props.suggestions.length - 1,
                        })}
                      />
                    </div>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          )}
        </div>
      </div>
    );
  }
}
