import React, { Component } from "react";
import { ListGroup, ListGroupItem, Button } from "reactstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Spinner from "react-spinkit";
import classNames from "classnames";
import "./ItemSearchList.scss";

const customItemKey = "CUSTOM_ITEM_KEY";

class ItemSearchList extends Component {
  constructor(props) {
    super(props);

    this.onSuggestionClicked = this.onSuggestionClicked.bind(this);
    this.renderCell = this.renderCell.bind(this);
  }
  render() {
    return (
      <div className="item-list row justify-content-center flex-stretching d-flex">
        <div
          className="col-12 flex-stretching d-flex"
          style={{ padding: "0px 24px" }}
        >
          {this.props.isFetching ? (
            <Spinner
              style={{
                margin: "0 auto",
              }}
              name="line-scale"
              color="#a2a8b5"
              fadeIn="quarter"
            />
          ) : (
            <TransitionGroup component={ListGroup}>
              {this.props.shouldDisplaySuggestions
                ? [...this.props.suggestions, { name: customItemKey }].map(
                    this.renderCell
                  )
                : this.props.items.map(this.renderCell)}
            </TransitionGroup>
          )}
        </div>
      </div>
    );
  }
  renderCell(item, index, array) {
    const isLastCell = index === array.length - 1;
    const isCustomItemCell = item.name === customItemKey;
    return (
      <CSSTransition
        key={index}
        classNames="suggestion-fade"
        timeout={300}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <ListGroupItem
          key={index}
          tag={this.props.shouldDisplaySuggestions ? "button" : "li"}
          onClick={
            this.props.shouldDisplaySuggestions
              ? isCustomItemCell
                ? () => this.props.onCustomItemClicked()
                : () => this.onSuggestionClicked(item)
              : null
          }
          action
        >
          <div
            className="row justify-content-between align-items-center no-gutters content"
            style={{ padding: "12px 16px" }}
          >
            {!isCustomItemCell && (
              <div
                className={classNames({
                  "col-6 col-sm-7 col-md-8": !this.props
                    .shouldDisplaySuggestions,
                  "col-12": this.props.shouldDisplaySuggestions,
                })}
              >
                <p className="item-name">{item.name}</p>
              </div>
            )}
            {isCustomItemCell && (
              <div className="col-12" style={{ textAlign: "center" }}>
                <p className="item-name" style={{ color: "#fa8872" }}>
                  Gesuchter Gegenstand ist nicht dabei?
                </p>
              </div>
            )}
            <div
              className={classNames({
                "col-6 col-sm-5 col-md-4": true,
                "d-none": this.props.shouldDisplaySuggestions,
              })}
            >
              <div className="row justify-content-between align-items-center no-gutters">
                <div className="col-auto">
                  <Button
                    className="quantity decrease-count"
                    onClick={() => this.props.onDecreaseItemCount(item)}
                  >
                    −
                  </Button>
                </div>
                <div className="col-auto">{`  ${item.quantity}  `}</div>
                <div className="col-auto">
                  <Button
                    className="quantity increase-count"
                    onClick={() => this.props.onIncreaseItemCount(item)}
                  >
                    +
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames({ separator: true, "d-none": isLastCell })}
          />
        </ListGroupItem>
      </CSSTransition>
    );
  }
  onSuggestionClicked(suggestion) {
    this.props.onAddItem(suggestion);
  }
}

export default ItemSearchList;
