import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-spinkit";
import { Button } from "reactstrap";
import { fetchOrder } from "../../lib/api";
import { setOrder } from "../../actions/order_actions";
import classNames from "classnames";
import Tracker from "../../lib/Tracker";
import { useHistory, useLocation } from "react-router";
import { Order } from "../../model/Order";
import { X, Check } from "react-feather";
import "./FinishContainer.scss";
import TagManager from "react-gtm-module";

export const FinishContainer: React.FC<{}> = () => {
  const [errorMessage, setErrorMessage] = useState<string>();

  const order: Order | undefined = useSelector((state: any) => {
    return state.order;
  });

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    Tracker.track("finish");

    const loadOrder = async (orderId: string) => {
      try {
        const res = await fetchOrder(orderId);
        const order = res.data as Order;
        dispatch(setOrder(order));
        if (order.state_type === "charge.pending") {
          const handle = setTimeout(() => {
            clearTimeout(handle);
            loadOrder(orderId);
          }, 2000);
        }
      } catch (error: any) {
        console.error(error);
        setErrorMessage(error.response ? error.response.data : error.message);
      }
    };

    // check for order in store or fetch from api
    if (!order) {
      console.log("ORDER NOT IN STORE");
      console.log(location);
      const orderId = location.search;
      if (orderId) {
        loadOrder(orderId);
      } else {
        setErrorMessage("Wir konnten leider keinen passenden Auftrag finden.");
      }
    } else if (order.state_type === "charge.pending") {
      loadOrder(order.id);
    }
  }, [dispatch, location, order]);

  console.log(order);

  let symbol = null;
  let headline = null;
  let subline = null;
  let hideButton = false;
  if (errorMessage) {
    symbol = <X color="#fa8872" style={{ width: "70%", height: "70%" }} />;
    headline = <p>Fehler</p>;
    subline = <p>{errorMessage}</p>;
  } else if (
    order &&
    (order.state_type === "charge.failed" ||
      order.state_type === "charge.cancelled")
  ) {
    symbol = <X color="#fa8872" style={{ width: "70%", height: "70%" }} />;
    headline = <p>Fehler</p>;
    subline = (
      <p>
        Die Zahlung für den Auftrag ist fehlgeschlagen. Bitte versuche es
        erneut.
      </p>
    );
  } else if (
    order &&
    (order.state_type === "pending" || order.state_type === "driver.accepted")
  ) {
    symbol = <Check color="#60cda6" style={{ width: "70%", height: "70%" }} />;
    headline = <p>Auftrag erfolgreich!</p>;
    subline = (
      <p>
        Du kannst dich jetzt zurücklehnen, wir erledigen den Rest für dich.
        Behalte dein Postfach im Auge!
      </p>
    );
    // trigger google tag manager ad words conversion tag
    TagManager.dataLayer({
      dataLayer: {
        price: order.price.customer,
        event: "booked",
      },
    });
  } else if (!order || (order && order.state_type === "charge.pending")) {
    symbol = <Spinner name="line-scale" color="#a2a8b5" fadeIn="quarter" />;
    headline = <p>Auftrag wird verarbeitet...</p>;
    subline = <p>Bitte hab noch einen Augenblick Geduld.</p>;
    hideButton = true;
  }

  return (
    <div className="finish-container">
      <div className="row justify-content-center">
        <div className="col-auto">
          <div className="check-badge">{symbol}</div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-auto headline">{headline}</div>
      </div>
      <div className="row justify-content-center">
        <div className="col-10 col-md-6 subline">{subline}</div>
      </div>
      <div
        className={classNames({
          "row justify-content-center": true,
          "d-none": hideButton,
        })}
      >
        <div className="col-auto again-button">
          <Button onClick={() => history.push("/")}>
            Zurück zur Startseite
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-6 bottom">
          <img
            src="/assets/images/banner-left.png"
            srcSet="/assets/images/banner-left.png 1x, /assets/images/banner-left@2x.png 2x, /assets/images/banner-left@3x.png 3x"
          />
        </div>
        <div className="col-6 bottom">
          <img
            src="/assets/images/banner-right.png"
            srcSet="/assets/images/banner-right.png 1x, /assets/images/banner-right@2x.png 2x, /assets/images/banner-right@3x.png 3x"
          />
        </div>
      </div>
    </div>
  );
};
