import React, { Component } from "react";
import Spinner from "react-spinkit";
import "./LoadingOverlay.scss";

export default class LoadingOverlay extends Component {
  render() {
    return (
      <div className="loading-overlay">
        <div className="row justify-content-center">
          <div className="col-auto">
            <Spinner name="line-scale" color="#ffffff" fadeIn="quarter" />
          </div>
        </div>
        {this.props.message && (
          <div className="row justify-content-center">
            <div className="col-10">{this.props.message}</div>
          </div>
        )}
      </div>
    );
  }
}
