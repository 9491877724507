import React from "react";
import classNames from "classnames";
import "./Header.scss";
import { useHistory, useLocation } from "react-router";
import { routeForPathname } from "../../../routes";
import { ChevronLeft } from "react-feather";

export const Header: React.FC<{}> = () => {
  const history = useHistory();
  const location = useLocation();

  const route = routeForPathname(location.pathname);

  return (
    <header className="navigation-header d-flex flex-stretching">
      <div className="container-fluid">
        <div className="row align-items-center" style={{ height: "100%" }}>
          <div
            className={classNames({
              "col-2": true,
              "d-sm-none": true,
              "back-button-holder": true,
              "d-none": !route.shouldDisplayBackButton,
            })}
          >
            <button onClick={history.goBack}>
              <ChevronLeft />
            </button>
          </div>
          <div
            className={classNames({
              "col-8": route.shouldDisplayBackButton,
              "col-12": !route.shouldDisplayBackButton,
              "col-sm-12": true,
            })}
          >
            <h1>{route.title}</h1>
          </div>
          <div className="col-2 hidden-md-up"></div>
        </div>
      </div>
    </header>
  );
};
