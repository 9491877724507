import {
  ADD_ITEM,
  ADD_CUSTOM_ITEM,
  INCREASE_ITEM_COUNT,
  DECREASE_ITEM_COUNT,
} from "../actions/items_actions";
import { v4 as uuidv4 } from "uuid";

export default function (state = [], action) {
  switch (action.type) {
    case ADD_ITEM: {
      const itemIndex = state.map((i) => i.id).indexOf(action.item.id);
      if (itemIndex === -1) {
        // add item
        const newState = Array.from(state);
        newState.push({
          name: action.item.name,
          id: action.item.id,
          quantity: 1,
          type: "builtin",
        });
        return newState;
      } else {
        // increase count
        const oldItem = state[itemIndex];
        const newState = Array.from(state);
        newState[itemIndex] = Object.assign({}, oldItem, {
          quantity: oldItem.quantity + 1,
        });
        return newState;
      }
    }
    case ADD_CUSTOM_ITEM: {
      if (state.map((i) => i.id).indexOf(action.item.id) === -1) {
        const newState = Array.from(state);
        newState.push({
          name: action.item.notes,
          id: uuidv4(),
          quantity: 1,
          type: "custom",
          original: action.item,
        });
        return newState;
      }
      break;
    }
    case INCREASE_ITEM_COUNT: {
      const itemIndex = state.map((i) => i.id).indexOf(action.item.id);
      const oldItem = state[itemIndex];
      const newState = Array.from(state);
      newState[itemIndex] = Object.assign({}, oldItem, {
        quantity: oldItem.quantity + 1,
      });
      return newState;
    }
    case DECREASE_ITEM_COUNT: {
      const itemIndex = state.map((i) => i.id).indexOf(action.item.id);
      const oldItem = state[itemIndex];
      const newState = Array.from(state);
      if (!oldItem) return newState;
      if (oldItem.quantity <= 1) {
        newState.splice(itemIndex, 1);
      } else {
        newState[itemIndex] = Object.assign({}, oldItem, {
          quantity: oldItem.quantity - 1,
        });
      }
      return newState;
    }
    default:
      return state;
  }
}
