import { APIUrl } from "./constants";
import axios from "axios";
import { CreateOrderRequest, Order } from "../model/Order";

export function fetchItems(query: string) {
  const url = `${APIUrl}/items?query=${encodeURI(query)}`;
  return axios.get(url);
}

export function fetchEstimate(params: any) {
  const url = `${APIUrl}/orders/orders/estimate`;
  return axios.post(url, params);
}

export function fetchCities() {
  const url = `${APIUrl}/orders/location/cities`;
  return axios.get(url);
}

export function createOrder(params: CreateOrderRequest) {
  const url = `${APIUrl}/orders/orders`;
  return axios.post<Order>(url, params);
}

export function fetchOrder(orderId: string) {
  const url = `${APIUrl}/orders/orders/${encodeURI(orderId)}`;
  return axios.get(url);
}

export function fetchPaymentToken() {
  const url = `${APIUrl}/orders/payment/token`;
  return axios.get<{ token: string }>(url);
}
