import { Component } from "react";
import { Button } from "reactstrap";
import { Check } from "react-feather";
import "./AddressButton.scss";
import { ClickIndicator } from "../shared/ClickIndicator";

export default class AddressButton extends Component {
  render() {
    return (
      <Button className="address" onClick={this.props.onAddressClicked}>
        {this.props.address.location ? (
          <>
            {this.props.address.location.title}
            <Check
              color="#60cda6"
              style={{
                position: "absolute",
                top: "8px",
                right: "12px",
                width: "30px",
                height: "auto",
              }}
            />
          </>
        ) : (
          this.props.placeholder
        )}
        {this.props.shouldDisplayClickIndicator && (
          <>
            &nbsp;&nbsp;
            <ClickIndicator />
          </>
        )}
      </Button>
    );
  }
}
