import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import "moment/locale/de";
import "./DatePicker.scss";
import { DayModifiers } from "react-day-picker/types/Modifiers";
import { FormGroup, Label } from "reactstrap";

interface Props {
  selectedDate: Date;
  onDateSelected: (day: Date) => void;
}

export const DatePicker: React.FC<Props> = (props) => {
  const handleDayChange = (day: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return;
    }
    props.onDateSelected(day);
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <FormGroup style={{ margin: 0 }}>
          <Label>Datum</Label>
          <DayPickerInput
            value={props.selectedDate}
            onDayChange={handleDayChange}
            formatDate={MomentLocaleUtils.formatDate}
            parseDate={MomentLocaleUtils.parseDate}
            format="dddd, DD.MM.YY"
            dayPickerProps={{
              disabledDays: [{ before: new Date() }, { daysOfWeek: [0] }],
              localeUtils: MomentLocaleUtils,
              locale: "de",
            }}
          />
        </FormGroup>
      </div>
    </div>
  );
};
