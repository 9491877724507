export const ADD_ITEM = "ADD_ITEM"
export const ADD_CUSTOM_ITEM = "ADD_CUSTOM_ITEM"
export const INCREASE_ITEM_COUNT = "INCREASE_ITEM_COUNT"
export const DECREASE_ITEM_COUNT = "DECREASE_ITEM_COUNT"

export function addItem(item){
	return {
		type: ADD_ITEM,
		item: item
	}
}

export function addCustomItem(item){
	return {
		type: ADD_CUSTOM_ITEM,
		item: item
	}
}

export function increaseItemCount(item){
	return {
		type: INCREASE_ITEM_COUNT,
		item: item
	}
}

export function decreaseItemCount(item){
	return {
		type: DECREASE_ITEM_COUNT,
		item: item
	}
}
