import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  addCustomItem,
  increaseItemCount,
  decreaseItemCount,
} from "../../actions/items_actions";
import { Button, Input, ModalBody, ModalHeader } from "reactstrap";
import { fetchItems } from "../../lib/api";
import Card from "../shared/Card/Card";
import FillingWrapper from "../shared/FillingWrapper/FillingWrapper";
import ItemSearchList from "../ItemSearchList/ItemSearchList";
import CustomItemCreator from "../CustomItemCreator/CustomItemCreator";
import { BottomNavigationBar } from "../shared/BottomNavigationBar/BottomNavigationBar";
import Tracker from "../../lib/Tracker";
import styled from "styled-components";
import { Search } from "react-feather";
import { MovematesModal } from "../shared/MovematesModal";

const StyledInput = styled(Input)`
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  background-color: #e1e9eb;
  color: #266473;
  outline: none;
  border: none;
  text-align: left;

  &:focus {
    background-color: #e1e9eb;
  }

  &:placeholder {
    font-weight: 400;
  }
`;

const LinkButton = styled(Button)`
  cursor: pointer;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  font-size: 0.9em;
  background-color: #ffffff;
  color: #fa8872;
  padding: 2px 15px;

  &:hover,
  :active,
  :focus {
    background-color: #ffffff !important;
    color: #323643 !important;
  }
`;

export const ItemsContainer: React.FC<{}> = () => {
  const [inputValue, setInputValue] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [shouldDisplaySuggestions, setShouldDisplaySuggestions] = useState(
    false
  );
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [isCustomItemModalOpen, setIsCustomItemModalOpen] = useState(false);

  const { items } = useSelector((state: any) => ({
    items: state.items,
  }));
  const dispatch = useDispatch();

  const itemsInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    itemsInputRef.current?.focus();
    Tracker.track("items");
  });

  useEffect(() => {
    const search = async (query: string) => {
      if (query.length > 1) {
        setIsFetching(true);
        try {
          const res = await fetchItems(query);
          setSuggestions(res.data.items);
          setShouldDisplaySuggestions(true);
        } catch (error) {
          console.error(error);
        } finally {
          setIsFetching(false);
        }
      } else {
        setSuggestions([]);
      }
    };
    search(inputValue);
  }, [inputValue]);

  const onInputChange = (event: any) => {
    setInputValue(event.target.value);
    setShouldDisplaySuggestions(event.target.value.length !== 0);
  };

  const handleItemAdded = (item: any) => {
    setInputValue("");
    setShouldDisplaySuggestions(false);
    dispatch(addItem(item));
  };
  const handleCustomItemAdded = (item: any) => {
    setInputValue("");
    setShouldDisplaySuggestions(false);
    setIsCustomItemModalOpen(false);
    dispatch(addCustomItem(item));
  };
  const handleItemCountIncrease = (item: any) => {
    dispatch(increaseItemCount(item));
  };
  const handleItemCountDecrease = (item: any) => {
    dispatch(decreaseItemCount(item));
  };

  return (
    <>
      <FillingWrapper>
        <Card style={{ flexDirection: "column" }}>
          <div className="row justify-content-center" style={{ flexShrink: 0 }}>
            <div
              className="col-12 items-search-bar"
              style={{ padding: "12px 24px" }}
            >
              <StyledInput
                type="text"
                onChange={onInputChange}
                value={inputValue}
                placeholder="z.B. Schreibtisch"
                innerRef={itemsInputRef}
              />
              <Search
                style={{ position: "absolute", top: "18px", right: "34px" }}
                color="#6d757d"
              />
            </div>
          </div>
          <div style={{ flexGrow: 1, overflowY: "auto", overflowX: "hidden" }}>
            {inputValue.length === 0 &&
            suggestions.length === 0 &&
            items.length === 0 ? (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "80px",
                  color: "#a2a8b5",
                }}
              >
                <p>
                  Was dürfen wir für dich transportieren? <br /> Füge über die
                  Suche alle
                  <br /> zu transportierenden Gegenstände hinzu.
                </p>
              </div>
            ) : (
              <ItemSearchList
                suggestions={suggestions}
                shouldDisplaySuggestions={shouldDisplaySuggestions}
                isFetching={isFetching}
                items={items}
                onAddItem={handleItemAdded}
                onIncreaseItemCount={handleItemCountIncrease}
                onDecreaseItemCount={handleItemCountDecrease}
                onCustomItemClicked={() => setIsCustomItemModalOpen(true)}
              />
            )}
          </div>

          <div style={{ flexShrink: 0 }}>
            <div className="row justify-content-center">
              <div className="col-10" style={{ textAlign: "center" }}>
                <LinkButton
                  onClick={() => setIsCustomItemModalOpen((prev) => !prev)}
                >
                  Gesuchter Gegenstand ist nicht dabei?
                </LinkButton>
              </div>
              <div className="col-10">
                <BottomNavigationBar
                  shouldShowNextButton={true}
                  backTitle="Zurück zur Adressauswahl"
                  isNextActive={items.length > 0}
                  nextTitle="Datum & Uhrzeit wählen"
                  nextRoute="/datetime"
                />
              </div>
            </div>
          </div>
        </Card>
      </FillingWrapper>
      <MovematesModal
        isOpen={isCustomItemModalOpen}
        toggle={() => setIsCustomItemModalOpen((prev) => !prev)}
        centered
      >
        <ModalHeader>Eigenen Gegenstand hinzufügen</ModalHeader>
        <ModalBody>
          <p>
            Füge hier einen Gegenstand hinzu, den du nicht in unserer
            Gegenstandsdatenbank gefunden hast.
          </p>
          <CustomItemCreator
            onAddItem={handleCustomItemAdded}
            onCancel={() => setIsCustomItemModalOpen(false)}
            style={{ marginTop: "10px" }}
          />
        </ModalBody>
      </MovematesModal>
    </>
  );
};
