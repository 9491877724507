import React, {Component} from 'react'
import './CenteredTitle.scss'

export default class CenteredTitle extends Component {
  render() {
    return (
      <div className='centered-title' {...this.props}>
        {this.props.title}
      </div>
    )
  }
}
