import React from "react";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../../routes";
import "./ProgressIndicator.scss";

const buttonStyles = {
  filled: {
    backgroundColor: "#ffffff",
    cursor: "pointer",
  },
  outlined: {
    backgroundColor: "transparent",
  },
};

export const ProgressIndicator: React.FC<{}> = () => {
  const history = useHistory();
  const location = useLocation();

  const lastFilledIndex = routes
    .map((r) => r.pathname)
    .indexOf(location.pathname);

  return (
    <div className="progress-button-holder">
      {routes.map((route, index) => {
        return (
          <div key={index}>
            <button
              style={
                index <= lastFilledIndex
                  ? buttonStyles.filled
                  : buttonStyles.outlined
              }
              onClick={() => {
                if (index <= lastFilledIndex) {
                  history.push(route.pathname);
                }
              }}
            ></button>
            {index < routes.length - 1 && <div className="separator"></div>}
          </div>
        );
      })}
    </div>
  );
};
