import React from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";

const StyledContainer = styled.div`
  border: 2px solid #fa8872;
  border-radius: 8px;
  padding: ${(props: { size: string }) =>
    props.size === "sm" ? "2px 8px" : "8px 36px"};
  display: inline-block;
  text-align: center;
`;

const Headline = styled.span`
  color: #323643;
  font-size: 1em;
  font-weight: bold;
`;

const Price = styled.span`
  color: #fa8872;
  font-size: ${(props: { size: string }) =>
    props.size === "sm" ? "1.5em" : "2em"};
  font-weight: bold;
  line-height: 1.1em;
`;

const Subline = styled.span`
  color: #323643;
  font-size: 1em;
`;

interface Props {
  isFetching: boolean;
  price?: number;
  size: "sm" | "lg";
}

export const PriceTag: React.FC<Props> = (props) => {
  return (
    <StyledContainer size={props.size}>
      <Headline>Movemates-Festpreis</Headline>
      <br />
      {props.isFetching ? (
        <Spinner name="line-scale" color="#ffffff" fadeIn="quarter" />
      ) : (
        <>
          <Price size={props.size}>
            {((props.price || 0) / 100).toLocaleString("de-DE", {
              minimumFractionDigits: 2,
            })}
            €
          </Price>
          <br />
          <Subline>inkl. 19% MwSt.</Subline>
        </>
      )}
    </StyledContainer>
  );
};
