import React from "react";
import styled from "styled-components";

const InnerRing = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #1890ff;
  opacity: 0.7;
`;

const OuterRing = styled.div`
  position: absolute;
  top: -13px;
  left: -13px;
  border: 5px solid #1890ff;
  border-radius: 23px;
  height: 46px;
  width: 46px;
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;

  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    25% {
      opacity: 0.7;
    }
    50% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const ClickIndicator: React.FC<{}> = () => {
  return (
    <div
      style={{
        width: "20px",
        height: "20px",
        position: "relative",
        display: "inline-block",
        verticalAlign: "middle",
      }}
    >
      <InnerRing />
      <OuterRing />
    </div>
  );
};
