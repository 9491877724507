import React from "react";
import { CustomInput, Form, FormGroup, Input, Label } from "reactstrap";
import styled from "styled-components";
import "./TimePicker.scss";

const BigStyledInput = styled(Input)`
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-color: #e1e9eb;
  text-align: center;
  font-size: 2em;
  color: #323643;
  &:focus {
    outline: none;
  }
`;

interface Props {
  timeValue: string;
  onTimeChange: (time: string) => void;
}

export const TimePicker: React.FC<Props> = (props) => {
  return (
    <div className="row no-gutters justify-content-center time-input-wrapper">
      <div className="col-12">
        <Form>
          <FormGroup style={{ margin: 0 }}>
            <Label>Startzeit</Label>
            <BigStyledInput
              className="time-input"
              type="time"
              value={props.timeValue}
              onChange={(e: any) => props.onTimeChange(e.target.value)}
              required
            />
          </FormGroup>
        </Form>
      </div>
      <div className="col-12">
        <Form>
          <FormGroup>
            <CustomInput
              type="range"
              name="range"
              id="exampleRange"
              min="360"
              max="1320"
              value={timeStringToSliderValue(props.timeValue)}
              step="15"
              onChange={(e) =>
                props.onTimeChange(
                  sliderValueToTimeString(parseInt(e.target.value))
                )
              }
            />
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};

const sliderValueToTimeString = (sliderValue: number): string => {
  const minutes = sliderValue % 60;
  const hours = (sliderValue - minutes) / 60;

  console.log(`${hours}:${minutes}`);

  return `${String(hours).length === 1 ? "0" + hours : hours}:${
    String(minutes).length === 1 ? "0" + minutes : minutes
  }`;
};

const timeStringToSliderValue = (time: string): number => {
  const hours = parseInt(time.split(":")[0]);
  const minutes = parseInt(time.split(":")[1]);
  return hours * 60 + minutes;
};
