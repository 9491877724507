export enum Stage {
  Development = "dev",
  Staging = "staging",
  Production = "production",
}

export const stage = process.env.REACT_APP_CUSTOM_STAGE as Stage;

const APIUrls: Record<Stage, string> = {
  dev: "https://dev-api.movemates.de",
  staging: "https://staging-api.movemates.de",
  production: "https://api.movemates.de",
};

export const APIUrl = APIUrls[stage];

const appUrls: Record<Stage, string> = {
  dev: "http://localhost:3000",
  staging: "https://beta.movemates.de",
  production: "https://app.movemates.de",
};

export const appUrl = appUrls[stage];

export const hereApiKey = "i8gIFPK9EdaHdxJ_6kyy1MH3Q0hMzb6CkAmM4EQ84ro";
export const mapBoxToken =
  "pk.eyJ1Ijoic3BlZGlmdXgtMSIsImEiOiJja2hkaHJoczIwMXIyMnJtcHEwM2pvMHdkIn0.R8k5z6_qMdudamWiTix93g";
export const mixpanelToken = "7f2fab8440e6d1f098d66c61018a91af";
export const braintreeMerchantId = "zk8j396y4y9ft3jd";
export const googlePayMerchantId = "BCR2DN6TZPYI3RTM";

export const isCordova = process.env.REACT_APP_CUSTOM_CORDOVA === "cordova";
