import React, { Component } from "react";
import {
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Collapse,
  Alert,
} from "reactstrap";
import FillingWrapper from "../shared/FillingWrapper/FillingWrapper";
import { isCordova } from "../../lib/constants";
import Phone from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./ContactForm.scss";
import styled from "styled-components";
import { InfoPopover } from "../shared/InfoPopover";

export const StyledLabel = styled(Label)`
  margin-bottom: 0.1rem;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 0.6rem;
`;

const AGBLink = () => {
  if (isCordova) {
    return (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          window.cordova.InAppBrowser.open(
            "https://movemates.de/agb.html",
            "_blank",
            "location=no"
          );
        }}
      >
        allgemeinen Geschäftsbedingungen
      </a>
    );
  } else {
    return (
      <a
        href="https://movemates.de/agb.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        allgemeinen Geschäftsbedingungen
      </a>
    );
  }
};

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.handleValueChange = this.handleValueChange.bind(this);
  }
  render() {
    return (
      <FillingWrapper className="contact-form-wrapper">
        <Form>
          <div className="row">
            <div className="col-12 col-sm-6">
              <StyledFormGroup>
                <StyledLabel for="firstname">Vorname</StyledLabel>
                <Input
                  type="text"
                  name="firstname"
                  id="firstname"
                  value={this.props.contact.first_name}
                  valid={this.props.validation.first_name}
                  onChange={(e) => {
                    this.handleValueChange("first_name", e.target.value);
                  }}
                />
                <FormFeedback>Bitte gib deinen Vornamen ein.</FormFeedback>
              </StyledFormGroup>
            </div>
            <div className="col-12 col-sm-6">
              <StyledFormGroup>
                <StyledLabel for="lastname">Nachname</StyledLabel>
                <Input
                  type="text"
                  name="lastname"
                  id="lastname"
                  value={this.props.contact.last_name}
                  valid={this.props.validation.last_name}
                  onChange={(e) => {
                    this.handleValueChange("last_name", e.target.value);
                  }}
                />
                <FormFeedback>Bitte gib deinen Nachnamen ein.</FormFeedback>
              </StyledFormGroup>
            </div>
            <div className="col-12">
              <StyledFormGroup>
                <StyledLabel for="emailaddress">
                  E-Mail Adresse{" "}
                  <InfoPopover
                    color="#000000"
                    body="An diese E-Mail Adresse senden wir deine Auftragsbestätigung."
                  />
                </StyledLabel>
                <Input
                  type="email"
                  name="emailaddress"
                  id="emailaddress"
                  value={this.props.contact.email}
                  valid={this.props.validation.email}
                  onChange={(e) => {
                    this.handleValueChange("email", e.target.value);
                  }}
                />
                <FormFeedback>Die E-Mail Adresse ist ungültig.</FormFeedback>
              </StyledFormGroup>
            </div>
            <div className="col-12">
              <StyledFormGroup>
                <StyledLabel for="phonenumber">
                  Telefonnummer (mobil){" "}
                  <InfoPopover
                    color="#000000"
                    body="An diese Telefonnummer senden wir eine SMS mit Livetracking-Link, sobald wir uns auf den Weg zur Abholadresse machen."
                  />
                </StyledLabel>
                <Phone
                  name="phonenumber"
                  id="phonenumber"
                  country="DE"
                  defaultCountry="DE"
                  placeholder=""
                  indicateInvalid={
                    this.props.validation.phone === null
                      ? false
                      : !this.props.validation.phone
                  }
                  error="Die Telefonnummer ist ungültig."
                  value={this.props.contact.phone}
                  onChange={(phone) =>
                    this.handleValueChange("phone", phone || "")
                  }
                />
              </StyledFormGroup>
            </div>

            <div className="col-12" style={{ marginTop: "10px" }}>
              <StyledFormGroup>
                <Input
                  type="checkbox"
                  name="is_alt_from_open"
                  id="is_alt_from_open"
                  checked={this.props.contact.has_alt_from}
                  onChange={() => {
                    this.handleValueChange(
                      "has_alt_from",
                      !this.props.contact.has_alt_from
                    );
                  }}
                />
                <StyledLabel for="is_alt_from_open">
                  Abweichender Kontakt bei Abholung
                </StyledLabel>
              </StyledFormGroup>
            </div>
            <div className="col-12">
              <Collapse isOpen={this.props.contact.has_alt_from}>
                <div style={{ paddingLeft: "24px" }}>
                  <StyledFormGroup>
                    <StyledLabel for="alt_from_name">Name</StyledLabel>
                    <Input
                      type="text"
                      name="alt_from_name"
                      id="alt_from_name"
                      value={this.props.contact.alt_from_name}
                      valid={this.props.validation.alt_from_name}
                      onChange={(e) => {
                        this.handleValueChange("alt_from_name", e.target.value);
                      }}
                    />
                    <FormFeedback>Bitte gib einen Namen ein.</FormFeedback>
                  </StyledFormGroup>
                  <StyledFormGroup>
                    <StyledLabel for="alt_from_phonenumber">
                      Telefonnummer (mobil){" "}
                      <InfoPopover
                        color="#000000"
                        body="Auch an diese Telefonnummer senden wir eine SMS mit Livetracking-Link, sobald wir uns auf den Weg zur Abholadresse machen."
                      />
                    </StyledLabel>
                    <Phone
                      name="alt_from_phonenumber"
                      id="alt_from_phonenumber"
                      country="DE"
                      defaultCountry="DE"
                      placeholder=""
                      indicateInvalid={
                        this.props.validation.alt_from_phone === null
                          ? false
                          : !this.props.validation.alt_from_phone
                      }
                      error="Die Telefonnummer ist ungültig."
                      value={this.props.contact.alt_from_phone}
                      onChange={(phone) =>
                        this.handleValueChange("alt_from_phone", phone || "")
                      }
                    />
                  </StyledFormGroup>
                </div>
              </Collapse>
            </div>

            <div className="col-12">
              <StyledFormGroup>
                <Input
                  type="checkbox"
                  name="is_alt_to_open"
                  id="is_alt_to_open"
                  checked={this.props.contact.has_alt_to}
                  onChange={() => {
                    this.handleValueChange(
                      "has_alt_to",
                      !this.props.contact.has_alt_to
                    );
                  }}
                />
                <StyledLabel for="is_alt_to_open">
                  Abweichender Kontakt bei Lieferung
                </StyledLabel>
              </StyledFormGroup>
            </div>
            <div className="col-12">
              <Collapse isOpen={this.props.contact.has_alt_to}>
                <div style={{ paddingLeft: "24px" }}>
                  <StyledFormGroup>
                    <StyledLabel for="alt_to_name">Name</StyledLabel>
                    <Input
                      type="text"
                      name="alt_to_name"
                      id="alt_to_name"
                      value={this.props.contact.alt_to_name}
                      valid={this.props.validation.alt_to_name}
                      onChange={(e) => {
                        this.handleValueChange("alt_to_name", e.target.value);
                      }}
                    />
                    <FormFeedback>Bitte gib einen Namen ein.</FormFeedback>
                  </StyledFormGroup>
                  <StyledFormGroup>
                    <StyledLabel for="alt_to_phonenumber">
                      Telefonnummer (mobil){" "}
                      <InfoPopover
                        color="#000000"
                        body="Auch an diese Telefonnummer senden wir eine SMS mit Livetracking-Link, sobald wir uns auf den Weg zur Abholadresse machen."
                      />
                    </StyledLabel>
                    <Phone
                      name="alt_to_phonenumber"
                      id="alt_to_phonenumber"
                      country="DE"
                      defaultCountry="DE"
                      placeholder=""
                      indicateInvalid={
                        this.props.validation.alt_to_phone === null
                          ? false
                          : !this.props.validation.alt_to_phone
                      }
                      error="Die Telefonnummer ist ungültig."
                      value={this.props.contact.alt_to_phone}
                      onChange={(phone) =>
                        this.handleValueChange("alt_to_phone", phone || "")
                      }
                    />
                  </StyledFormGroup>
                </div>
              </Collapse>
            </div>

            {/* INVOICE */}
            <div className="col-12" style={{ marginBottom: "8px" }}>
              <StyledFormGroup>
                <Input
                  type="checkbox"
                  name="wants_invoice"
                  id="wants_invoice"
                  checked={this.props.contact.wants_invoice}
                  onChange={() => {
                    this.handleValueChange(
                      "wants_invoice",
                      !this.props.contact.wants_invoice
                    );
                  }}
                />
                <StyledLabel for="wants_invoice">
                  Ich benötige eine Rechnung
                </StyledLabel>
              </StyledFormGroup>
            </div>
            <div
              className="col-12"
              style={{ marginBottom: "10px", paddingLeft: "24px" }}
            >
              <Collapse isOpen={this.props.contact.wants_invoice}>
                <div className="row">
                  <div className="col-12">
                    <Alert color="secondary">
                      Gib hier deine Rechnungsadresse an. Wir senden dir die
                      Rechnung automatisch per E-Mail sobald dein Transport
                      abgeschlossen ist.
                    </Alert>
                  </div>
                  <div className="col-12 col-md-6">
                    <StyledFormGroup>
                      <StyledLabel for="invoice_company_name">
                        Firma
                      </StyledLabel>
                      <Input
                        type="text"
                        name="invoice_company_name"
                        id="invoice_company_name"
                        value={this.props.contact.invoice_company_name}
                        valid={this.props.validation.invoice_company_name}
                        onChange={(e) => {
                          this.handleValueChange(
                            "invoice_company_name",
                            e.target.value
                          );
                        }}
                      />
                    </StyledFormGroup>
                  </div>
                  <div className="col-12 col-md-6">
                    <StyledFormGroup>
                      <StyledLabel for="invoice_name">Name</StyledLabel>
                      <Input
                        type="text"
                        name="invoice_name"
                        id="invoice_name"
                        value={this.props.contact.invoice_name}
                        valid={this.props.validation.invoice_name}
                        onChange={(e) => {
                          this.handleValueChange(
                            "invoice_name",
                            e.target.value
                          );
                        }}
                      />
                      <FormFeedback>Bitte gib einen Namen ein.</FormFeedback>
                    </StyledFormGroup>
                  </div>
                  <div className="col-8">
                    <StyledFormGroup>
                      <StyledLabel for="invoice_street">Straße</StyledLabel>
                      <Input
                        type="text"
                        name="invoice_street"
                        id="invoice_street"
                        value={this.props.contact.invoice_street}
                        valid={this.props.validation.invoice_street}
                        onChange={(e) => {
                          this.handleValueChange(
                            "invoice_street",
                            e.target.value
                          );
                        }}
                      />
                      <FormFeedback>Bitte gib eine Straße ein.</FormFeedback>
                    </StyledFormGroup>
                  </div>
                  <div className="col-4">
                    <StyledFormGroup>
                      <StyledLabel for="invoice_house_number">
                        Hausnummer
                      </StyledLabel>
                      <Input
                        type="text"
                        name="invoice_house_number"
                        id="invoice_house_number"
                        value={this.props.contact.invoice_house_number}
                        valid={this.props.validation.invoice_house_number}
                        onChange={(e) => {
                          this.handleValueChange(
                            "invoice_house_number",
                            e.target.value
                          );
                        }}
                      />
                    </StyledFormGroup>
                  </div>
                  <div className="col-12 col-md-6">
                    <StyledFormGroup>
                      <StyledLabel for="invoice_zip">Postleitzahl</StyledLabel>
                      <Input
                        type="text"
                        name="invoice_zip"
                        id="invoice_zip"
                        value={this.props.contact.invoice_zip}
                        valid={this.props.validation.invoice_zip}
                        onChange={(e) => {
                          this.handleValueChange("invoice_zip", e.target.value);
                        }}
                      />
                    </StyledFormGroup>
                  </div>
                  <div className="col-12 col-md-6">
                    <StyledFormGroup>
                      <StyledLabel for="invoice_city">Stadt</StyledLabel>
                      <Input
                        type="text"
                        name="invoice_city"
                        id="invoice_city"
                        value={this.props.contact.invoice_city}
                        valid={this.props.validation.invoice_city}
                        onChange={(e) => {
                          this.handleValueChange(
                            "invoice_city",
                            e.target.value
                          );
                        }}
                      />
                    </StyledFormGroup>
                  </div>
                </div>
              </Collapse>
            </div>

            <div className="col-12">
              <StyledFormGroup>
                <StyledLabel for="comment">
                  Anmerkungen zum Auftrag (optional)
                </StyledLabel>
                <Input
                  type="textarea"
                  name="comment"
                  id="comment"
                  value={this.props.contact.notes}
                  rows={3}
                  placeholder="z.B. Tür im ersten Hinterhof, klingeln bei Müller zur Abholung"
                  onChange={(e) => {
                    this.handleValueChange("notes", e.target.value);
                  }}
                />
              </StyledFormGroup>
            </div>

            <div className="col-12">
              <StyledFormGroup>
                <Input
                  type="checkbox"
                  name="legal_accept"
                  id="legal_accept"
                  checked={this.props.contact.has_accepted_legal}
                  onChange={() => {
                    this.handleValueChange(
                      "has_accepted_legal",
                      !this.props.contact.has_accepted_legal
                    );
                  }}
                />
                <StyledLabel for="legal_accept">
                  Ich akzeptiere die <AGBLink />
                </StyledLabel>
              </StyledFormGroup>
            </div>
          </div>
        </Form>
      </FillingWrapper>
    );
  }
  handleValueChange(key, value) {
    const nextContact = Object.assign({}, this.props.contact, { [key]: value });
    this.props.onValueChange(nextContact);
  }
}
