import React, { Component } from "react";
import { Button, FormGroup, Input } from "reactstrap";
import classNames from "classnames";
import "./CustomItemCreator.scss";

export default class CustomItemCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      width: "",
      height: "",
      depth: "",
      weight: "",
    };

    this.isValidItem = this.isValidItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
  }
  render() {
    return (
      <div className="custom-item-creator">
        <div className="row justify-content-center">
          <div className="col-12">
            <FormGroup>
              <Input
                type="text"
                placeholder="Name des Gegenstands"
                value={this.state.name}
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                }}
              />
            </FormGroup>
          </div>
          <div className="col-6">
            <FormGroup>
              <Input
                type="number"
                placeholder="Breite in cm"
                value={this.state.width}
                onChange={(e) => {
                  this.setState({ width: parseInt(e.target.value) });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="number"
                placeholder="Höhe in cm"
                value={this.state.height}
                onChange={(e) => {
                  this.setState({ height: parseInt(e.target.value) });
                }}
              />
            </FormGroup>
          </div>
          <div className="col-6">
            <FormGroup>
              <Input
                type="number"
                placeholder="Tiefe in cm"
                value={this.state.depth}
                onChange={(e) => {
                  this.setState({ depth: parseInt(e.target.value) });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="number"
                placeholder="Gewicht in kg"
                value={this.state.weight}
                onChange={(e) => {
                  this.setState({ weight: parseInt(e.target.value) });
                }}
              />
            </FormGroup>
          </div>
        </div>
        <div className="row no-gutters justify-content-center">
          <div className="col-auto">
            <Button className="cancel" onClick={() => this.props.onCancel()}>
              Abbrechen
            </Button>
            <Button
              className={classNames({
                add: true,
                active: this.isValidItem(),
              })}
              onClick={() => {
                this.isValidItem() && this.handleAddClick();
              }}
            >
              Hinzufügen
            </Button>
          </div>
        </div>
      </div>
    );
  }
  isValidItem() {
    return (
      this.state.name.length > 0 &&
      this.state.width !== "" &&
      this.state.width > 0 &&
      this.state.height !== "" &&
      this.state.height > 0 &&
      this.state.depth !== "" &&
      this.state.depth > 0 &&
      this.state.weight !== "" &&
      this.state.weight > 0
    );
  }
  handleAddClick() {
    const item = {
      width: this.state.width,
      height: this.state.height,
      depth: this.state.depth,
      weight: this.state.weight,
      notes: this.state.name,
    };
    this.props.onAddItem(item);
    this.setState({
      isOpen: false,
      width: "",
      height: "",
      depth: "",
      weight: "",
      name: "",
    });
  }
}
