import { Map } from "./Map";
import { Layer, MapRef, Source } from "react-map-gl";
import { useEffect, useRef, useState } from "react";
import { compact } from "lodash";
import { decodePolyline, HereMapsClient } from "@silberistgold/orbit-kit";
import { hereApiKey } from "../../lib/constants";

const hereClient = new HereMapsClient(hereApiKey);

export const RouteMap = ({
  inititalCenter,
  startId,
  endId,
}: {
  inititalCenter: { lat: number; lon: number };
  startId?: string;
  endId?: string;
}) => {
  const [start, setStart] = useState<[number, number]>();
  const [end, setEnd] = useState<[number, number]>();
  const [route, setRoute] = useState<number[][]>();

  useEffect(() => {
    (async () => {
      if (startId) {
        const res = await hereClient.lookup({ id: startId, lang: ["de"] });
        if (res.position) setStart([res.position?.lng, res.position?.lat]);
      }
    })();
  }, [startId]);

  useEffect(() => {
    (async () => {
      if (endId) {
        const res = await hereClient.lookup({ id: endId, lang: ["de"] });
        if (res.position) setEnd([res.position?.lng, res.position?.lat]);
      }
    })();
  }, [endId]);

  useEffect(() => {
    (async () => {
      if (start && end) {
        const res = await hereClient.calculateRoute({
          origin: `${start[1]},${start[0]}`,
          destination: `${end[1]},${end[0]}`,
          transportMode: "car",
          return: ["polyline"],
          departureTime: "any",
        });
        const polyline = res.routes[0].sections[0].polyline;
        if (polyline) {
          const route = decodePolyline(polyline);
          // lat and long are switched
          setRoute(route.polyline.map((p) => [p[1], p[0]]));
        }
      }
    })();
  }, [start, end]);

  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    const points = compact([...((route ?? []) as any), start, end]);
    mapRef.current?.fitBounds(calculateBounds(points) as any, {
      padding: {
        top: 50,
        bottom: 70,
        left: 50,
        right: 50,
      },
      maxZoom: 14,
    });
  }, [route, start, end]);

  return (
    <Map
      ref={mapRef}
      initialViewState={{
        latitude: inititalCenter.lat,
        longitude: inititalCenter.lon,
        zoom: 5.2,
      }}
    >
      <Source
        id="tour-route-source"
        type="geojson"
        data={{
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: route ?? [],
          },
          properties: {},
        }}
      >
        <Layer
          id="tour-route-layer"
          type="line"
          layout={{
            "line-join": "round",
            "line-cap": "round",
          }}
          paint={{
            "line-color": "#fa8872",
            "line-width": 4,
            "line-opacity": 1,
          }}
        />
      </Source>
      <Source
        id="tour-stops-source"
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: compact([start, end]).map((p, i) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: p,
            },
            properties: {
              type: "circle",
              displayIndex: `${i + 1}`,
            },
          })),
        }}
      >
        <Layer
          id="tour-stops-circle-layer"
          type="circle"
          paint={{
            "circle-radius": 12,
            "circle-color": "#fa8872",
            "circle-opacity": 1.0,
          }}
        />
        <Layer
          id="tour-stops-text-layer"
          type="symbol"
          layout={{
            "text-optional": false,
            "text-field": ["get", "displayIndex"],
            "text-font": ["Arial Unicode MS Bold"],
            "text-size": 16,
          }}
          paint={{
            "text-color": "#ffffff",
            "text-opacity": 1.0,
          }}
        />
      </Source>
    </Map>
  );
};

export const calculateBounds = (
  i: [number | undefined, number | undefined][]
) => {
  const lons = compact(i.map((c) => c[0]));
  const lats = compact(i.map((c) => c[1]));
  if (lons.length === 0 || lats.length === 0) return undefined;
  const corner1 = [Math.min(...lons), Math.min(...lats)];
  const corner2 = [Math.max(...lons), Math.max(...lats)];

  return [corner1, corner2] as [[number, number], [number, number]];
};
