import { combineReducers } from "redux";
import UserInput from "./reducer_userinput";
import Items from "./reducer_items";
import Estimation from "./reducer_estimation";
import Order from "./reducer_order";

export const rootReducer = combineReducers({
  user_input: UserInput,
  items: Items,
  estimation: Estimation,
  order: Order,
});
