import moment from "moment";
import {
  SET_PICKUP,
  SET_DELIVERY,
  SET_DATE,
  SET_TIME,
  SET_CONTACT,
} from "../actions/userinput_action";

const isSaturday = moment().isoWeekday() === 6;

export default function (
  state = {
    pickup: {
      location: null,
      move_type: null,
      story: 0,
      elevator: false,
    },
    delivery: {
      location: null,
      move_type: null,
      story: 0,
      elevator: false,
    },
    contact: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      notes: "",
      has_alt_from: false,
      has_alt_to: false,
      wants_invoice: false,
      has_accepted_legal: true,
    },
    // skip sunday: on saturday add 49 hours (24 + 25) instead of 25
    date: moment()
      .minute(0)
      .add(isSaturday ? 49 : 25, "hours")
      .toJSON(),
    time: moment().minute(0).add(25, "hours").format("HH:mm"),
  },
  action
) {
  switch (action.type) {
    case SET_PICKUP:
      const pickup = Object.assign({}, state.pickup, action.payload);
      return Object.assign({}, state, { pickup });
    case SET_DELIVERY:
      const delivery = Object.assign({}, state.delivery, action.payload);
      return Object.assign({}, state, { delivery });
    case SET_DATE:
      return Object.assign({}, state, { date: action.date });
    case SET_TIME:
      return Object.assign({}, state, { time: action.time });
    case SET_CONTACT:
      return Object.assign({}, state, { contact: action.contact });
    default:
      return state;
  }
}
