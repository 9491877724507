import React, { Component } from "react";
import { Button } from "reactstrap";
import Switch from "react-switch";
import { floorAbbreviation } from "../../lib/formatters";
import { ReactComponent as Exclamation } from "../../icons/exclamation.svg";
import "./FloorElevatorPicker.scss";

export default class FloorElevatorPicker extends Component {
  render() {
    return (
      <div
        className="floor-elevator d-flex flex-column"
        style={{ flexGrow: "1" }}
      >
        <div
          className="col-12"
          style={{
            flex: "unset",
            flexGrow: "1",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div className="row justify-content-center">
            {/* Floor Buttons */}
            <div className="col-8 col-md-6 story-picker">
              <div className="row no-gutters justify-content-between align-items-center">
                <div className="col-12 text-center">
                  <h5>Stockwerk auswählen</h5>
                </div>
                <div className="col-auto">
                  <Button
                    className="story minus"
                    onClick={() => {
                      this.props.onStoryChange(
                        Math.max(this.props.story - 1, -2)
                      );
                    }}
                  >
                    −
                  </Button>
                </div>
                <div className="col-auto">
                  {floorAbbreviation(this.props.story)}
                </div>
                <div className="col-auto">
                  <Button
                    className="story plus"
                    onClick={() => {
                      this.props.onStoryChange(this.props.story + 1);
                    }}
                  >
                    +
                  </Button>
                </div>
              </div>
            </div>
            {/* Elevator Switch */}
            <div className="col-10 col-md-7 elevator-picker">
              <div className="row no-gutters justify-content-between align-items-center">
                <div className="col-4 elevator-label">
                  Kein
                  <br />
                  Aufzug
                </div>
                <div className="col-auto">
                  <Switch
                    onColor="#60cda6"
                    offColor="#e07469"
                    checked={this.props.hasElevator}
                    onChange={this.props.onElevatorChange}
                  />
                </div>
                <div className="col-4 elevator-label">
                  Aufzug
                  <br />
                  vorhanden
                </div>
              </div>
            </div>
            {/* Hint */}
            <div className="col-12 col-md-10 hint-label">
              <div className="hint-exclamation">
                <Exclamation />
              </div>
              <span>
                Der Aufzug muss für die von dir transportierten Gegenstände
                nutzbar sein.
              </span>
            </div>
          </div>
        </div>

        {/* Finish Button */}
        <div
          className="col-12"
          style={{
            flexShrink: "0",
            flex: "unset",
          }}
        >
          <Button className="finish active" onClick={this.props.onFinish}>
            Fertig
          </Button>
        </div>
      </div>
    );
  }
}
