import { SET_ESTIMATION } from "../actions/estimation_actions";

export default function (state = null, action) {
  switch (action.type) {
    case SET_ESTIMATION: {
      return action.estimation;
    }
    default:
      return state;
  }
}
