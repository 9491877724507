import { mapBoxToken } from "../../lib/constants";
import { forwardRef, ReactNode, useRef } from "react";
import ReactMapGL, { MapRef } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";
import "mapbox-gl/dist/mapbox-gl.css";

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  .mapboxgl-ctrl {
    display: none !important;
  }
`;

// fix for nasty mapbox gl transpiling bug in production builds. https://github.com/visgl/react-map-gl/issues/1266
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export const Map = forwardRef<
  MapRef,
  {
    initialViewState?: { latitude?: number; longitude?: number; zoom?: number };
    children?: ReactNode;
  }
>(({ initialViewState, children }, ref) => {
  const internalRef = useRef<MapRef | null>(null);

  return (
    <MapContainer>
      <ReactMapGL
        ref={(node) => {
          internalRef.current = node;
          if (ref) {
            if (typeof ref === "function") {
              ref(node);
            } else {
              ref.current = node;
            }
          }
        }}
        style={{ width: "100%", height: "100%" }}
        mapboxAccessToken={mapBoxToken}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        attributionControl={false}
        initialViewState={initialViewState}
      >
        {children}
      </ReactMapGL>
    </MapContainer>
  );
});
