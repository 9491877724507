import React, { Component } from "react";
import { Route } from "react-router-dom";
import { routes } from "../../routes";
import { AddressContainer } from "../containers/AddressContainer";
import { ItemsContainer } from "../containers/ItemsContainer";
import { DateTimeContainer } from "../containers/DateTimeContainer";
import { SummaryContainer } from "../containers/SummaryContainer";
import ContactContainer from "../containers/ContactContainer";
import { PaymentContainer } from "../containers/PaymentContainer";
import { FinishContainer } from "../containers/FinishContainer";
import { spring, AnimatedSwitch } from "react-router-transition";
import "./RouterComponent.scss";

const pathnames = routes.map((r) => {
  return r.pathname;
});
var lastPathname = "/";

const mapStyles = (styles, isBackward) => {
  return {
    opacity: styles.opacity,
    transform: `translateX(${
      isBackward ? styles.backward_translateX : styles.translateX
    }%)`,
  };
};

const bounce = (val) => {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
};

const bounceTransition = {
  atEnter: {
    opacity: 0,
    translateX: 100,
    backward_translateX: -100,
  },
  atLeave: {
    opacity: bounce(0),
    translateX: bounce(-100),
    backward_translateX: bounce(100),
  },
  atActive: {
    opacity: bounce(1),
    translateX: bounce(0),
    backward_translateX: bounce(0),
  },
};

export default class RouterComponent extends Component {
  render() {
    return (
      <Route
        render={({ location }) => {
          const isBackward =
            pathnames.indexOf(location.pathname) <
            pathnames.indexOf(lastPathname);
          lastPathname = location.pathname;
          return (
            <AnimatedSwitch
              atEnter={bounceTransition.atEnter}
              atLeave={bounceTransition.atLeave}
              atActive={bounceTransition.atActive}
              mapStyles={(styles) => {
                return mapStyles(styles, isBackward);
              }}
              className="route-wrapper"
              location={location}
            >
              <Route
                exact
                path="/"
                render={(routeInfo) => {
                  return <AddressContainer {...routeInfo} />;
                }}
              />
              <Route
                path="/items"
                render={(routeInfo) => {
                  return <ItemsContainer {...routeInfo} />;
                }}
              />
              <Route
                path="/datetime"
                render={(routeInfo) => {
                  return <DateTimeContainer {...routeInfo} />;
                }}
              />
              <Route
                path="/summary"
                render={(routeInfo) => {
                  return <SummaryContainer {...routeInfo} />;
                }}
              />
              <Route
                path="/contact"
                render={(routeInfo) => {
                  return <ContactContainer {...routeInfo} />;
                }}
              />
              <Route
                path="/payment"
                render={(routeInfo) => {
                  return <PaymentContainer {...routeInfo} />;
                }}
              />
              <Route
                path="/finish"
                render={(routeInfo) => {
                  return <FinishContainer {...routeInfo} />;
                }}
              />
            </AnimatedSwitch>
          );
        }}
      />
    );
  }
}
