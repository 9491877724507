import React from "react";
import styled from "styled-components";
import { ReactComponent as GIcon } from "../icons/g-icon.svg";
import { ReactComponent as FIcon } from "../icons/f-icon.svg";
import ReactStars from "react-rating-stars-component";
import * as _ from "lodash";

const StyledContainer = styled.div`
  -webkit-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.2);
  -moz-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.2);
  box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.2);
  border-radius: 8px;
  padding: 8px 12px;
  display: inline-block;
  text-align: center;
`;

const Headline = styled.span`
  color: #323643;
  font-size: 0.9em;
`;

const Rating = styled.span`
  color: #000000;
  font-size: 1em;
  font-weight: bold;
`;

interface Props {
  value: number;
  type: "google" | "facebook";
}

export const RatingTag: React.FC<Props> = (props) => {
  return (
    <StyledContainer>
      <div className="row justify-content-center align-items-center no-gutters">
        <div className="col-auto">
          {props.type === "google" ? (
            <GIcon style={{ width: "18px", height: "auto" }} />
          ) : (
            <FIcon style={{ width: "20px", height: "auto" }} />
          )}
          <br />
          <Headline>{_.capitalize(props.type)} Bewertung</Headline>
        </div>
      </div>
      <div className="row justify-content-center align-items-center no-gutters">
        <div className="col-auto">
          <Rating>
            {props.value.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
            })}
          </Rating>
        </div>
        <div className="col-auto" style={{ marginLeft: "12px" }}>
          <ReactStars
            size={18}
            value={props.value}
            edit={false}
            isHalf={true}
          />
        </div>
      </div>
    </StyledContainer>
  );
};
