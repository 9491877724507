import React from "react";
import { Info } from "react-feather";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";

interface Props {
  color?: string;
  header?: string;
  body: string;
  size?: number;
}

export const InfoPopover: React.FC<Props> = (props) => {
  const id = `info-i-${Math.round(Math.random() * 1000)}`;

  return (
    <>
      <Info
        color={props.color || "#6d757d"}
        id={id}
        width={props.size || 18}
        height={props.size || 18}
        style={{ verticalAlign: "text-bottom" }}
      />
      <UncontrolledPopover trigger="hover" target={id}>
        {props.header && <PopoverHeader>{props.header}</PopoverHeader>}
        <PopoverBody>{props.body}</PopoverBody>
      </UncontrolledPopover>
    </>
  );
};
