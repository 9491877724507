import { Button } from "reactstrap";
import classNames from "classnames";
import "./BottomNavigationBar.scss";
import { useHistory } from "react-router";
import { ArrowLeft, ArrowRight } from "react-feather";

interface Props {
  shouldShowNextButton: boolean;
  shouldShowBackButton?: boolean;
  isNextActive: boolean;
  nextRoute: string;
  onNextClicked?: () => void;
  backTitle?: string;
  nextTitle: string;
}

export const BottomNavigationBar = ({
  backTitle,
  shouldShowNextButton,
  shouldShowBackButton = true,
  isNextActive,
  nextRoute,
  onNextClicked,
  nextTitle,
}: Props) => {
  const history = useHistory();

  return (
    <div className="row justify-content-center no-gutters bottom-navigation-bar">
      {shouldShowBackButton && (
        <div className="d-none d-sm-block col-6">
          <Button className="back" onClick={history.goBack}>
            {backTitle}{" "}
            <ArrowLeft
              style={{ position: "absolute", top: "10px", left: "12px" }}
            />
          </Button>
        </div>
      )}
      <div
        className={classNames({
          "col-10 col-sm-6": true,
          "d-none": !shouldShowNextButton,
        })}
      >
        <Button
          className={classNames({
            next: true,
            active: isNextActive,
          })}
          onClick={() => {
            isNextActive && onNextClicked
              ? onNextClicked()
              : isNextActive && history.push(nextRoute);
          }}
        >
          {nextTitle}
          <ArrowRight
            style={{ position: "absolute", top: "10px", right: "12px" }}
          />
        </Button>
      </div>
    </div>
  );
};
