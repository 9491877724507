import { Component } from "react";
import "./OverlayCard.scss";

export default class Overlaycard extends Component {
  render() {
    return (
      <div className="overlay-card" {...this.props}>
        {this.props.children}
      </div>
    );
  }
}
