import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDate, setTime } from "../../actions/userinput_action";
import { Collapse } from "reactstrap";
import Card from "../shared/Card/Card";
import FillingWrapper from "../shared/FillingWrapper/FillingWrapper";
import { DatePicker } from "../DatePicker/DatePicker";
import { TimePicker } from "../TimePicker/TimePicker";
import { BottomNavigationBar } from "../shared/BottomNavigationBar/BottomNavigationBar";
import Tracker from "../../lib/Tracker";
import moment from "moment";
import styled from "styled-components";

const Regular = styled.span`
  color: #323643;
  margin: 20px 0px;
  font-size: 1em;
`;

export const DateTimeContainer: React.FC<{}> = () => {
  const { date, time } = useSelector((state: any) => {
    return {
      date: state.user_input.date,
      time: state.user_input.time,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    Tracker.track("datetime");
  }, []);

  const hasEnoughLeeway = () => {
    const selected = moment(date).set({
      hour: time.substr(0, 2),
      minute: time.substr(3, 2),
    });
    const limit = moment().add(3, "hours");
    return selected.isAfter(limit);
  };

  const isValidTime = () => {
    const timeRegExp = new RegExp("[0-9]{2}:[0-9]{2}");
    const isValid = timeRegExp.test(time);
    return isValid;
  };

  const onDateChange = (date: any) => {
    dispatch(setDate(date));
  };

  const onTimeChange = (time: any) => {
    dispatch(setTime(time));
  };

  return (
    <FillingWrapper>
      <Card style={{ flexDirection: "column" }}>
        <div style={{ flexGrow: 1, overflowY: "auto" }}>
          <div className="row no-gutters justify-content-center flex-stretching">
            <div
              className="col-10"
              style={{ marginTop: "36px", textAlign: "center" }}
            >
              <Regular>
                Wähle den Tag deines Transportes und den gewünschten
                Startzeitpunkt aus.
              </Regular>
            </div>
            <div className="col-11 col-md-10" style={{ marginTop: "24px" }}>
              <DatePicker
                onDateSelected={onDateChange}
                selectedDate={new Date(date)}
              />
            </div>
            <div className="col-11 col-md-10" style={{ marginTop: "24px" }}>
              <TimePicker onTimeChange={onTimeChange} timeValue={time} />
            </div>
          </div>
        </div>
        <div style={{ flexShrink: 0 }}>
          <div className="row no-gutters justify-content-center flex-stretching">
            <Collapse isOpen={!hasEnoughLeeway() && isValidTime()}>
              <div
                className="col-auto align-self-end"
                style={{ textAlign: "center" }}
              >
                <p className="alert-text">
                  Die Transportzeit muss mindestens drei Stunden nach der
                  aktuellen Zeit liegen.
                </p>
              </div>
            </Collapse>
            <Collapse isOpen={!isValidTime()}>
              <div
                className="col-auto align-self-end"
                style={{ textAlign: "center" }}
              >
                <p className="alert-text">
                  Bitte gib eine gültige Uhrzeit ein.
                </p>
              </div>
            </Collapse>
            <div className="col-10 align-self-end">
              <BottomNavigationBar
                shouldShowNextButton={true}
                backTitle="Zurück zu Gegenständen"
                isNextActive={hasEnoughLeeway() && isValidTime()}
                nextTitle="Preis kalkulieren"
                nextRoute="/summary"
              />
            </div>
          </div>
        </div>
      </Card>
    </FillingWrapper>
  );
};
