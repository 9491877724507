import React, { useEffect } from "react";
import { Header } from "../shared/Header/Header";
import RouterComponent from "../RouterComponent/RouterComponent";
import { ProgressIndicator } from "../shared/ProgressIndicator/ProgressIndicator";
import classNames from "classnames";
import { Transition } from "react-transition-group";
import "./Home.scss";
import { useLocation } from "react-router";
import { routeForPathname } from "../../routes";
import { ReactComponent as Boxes } from "../../icons/bg-boxes.svg";
import { ReactComponent as Truck } from "../../icons/truck.svg";
import { stage, Stage } from "../../lib/constants";

const transitionStyles = {
  entering: {
    opacity: 0,
    transform: "scale(1.2)",
  },
  entered: {
    opacity: 1,
    transform: "scale(1)",
  },
  exiting: {
    opacity: 1,
    transform: "scale(1)",
  },
  exited: {
    opacity: 0,
    transform: "scale(0.8)",
  },
  unmounted: {
    opacity: 0,
    transform: "scale(0.8)",
  },
};

export const Home: React.FC<{}> = () => {
  const location = useLocation();
  const route = routeForPathname(location.pathname);

  // initialize hotjar
  useEffect(() => {
    if (stage === Stage.Production) {
      (function (h: any, o: any, t: any, j: any) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 2057123, hjsv: 6 };
        const a = o.getElementsByTagName("head")[0];
        const r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }
  }, []);

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <div className="header-wrapper">
        <Header />
      </div>
      <div className="container master-layout">
        <div
          className="row justify-content-center no-gutters"
          style={{
            height: "100%",
          }}
        >
          <Transition in={route.shouldDisplayAttachmentImages} timeout={200}>
            {(state) => (
              <div
                className="d-none d-lg-flex col-lg-2 col-xl main-bg-left align-items-end justify-content-start"
                style={{
                  height: "100%",
                  zIndex: -5,
                }}
              >
                <Boxes
                  style={{
                    width: "60%",
                    marginLeft: "20%",
                    marginBottom: "10%",
                    height: "auto",
                    transition: `all 200ms ease-in-out`,
                    ...transitionStyles[state],
                  }}
                />
              </div>
            )}
          </Transition>

          <div
            className="col col-sm-12 col-md-10 col-lg-8 col-xl-7 main-container"
            style={{
              height: "100%",
            }}
          >
            <RouterComponent />
          </div>

          <Transition in={route.shouldDisplayAttachmentImages} timeout={300}>
            {(state) => (
              <div
                className="d-none d-lg-flex col col-lg-2 col-xl main-bg-right align-items-end justify-content-end"
                style={{
                  height: "100%",
                  zIndex: -5,
                }}
              >
                <Truck
                  style={{
                    width: "80%",
                    marginBottom: "10%",
                    height: "auto",
                    transition: `all 200ms ease-in-out`,
                    ...transitionStyles[state],
                  }}
                />
              </div>
            )}
          </Transition>
        </div>
      </div>
      <div
        className={classNames({
          "main-bottom-space": true,
          "d-none": !route.shouldDisplayProgressIndicator,
        })}
      >
        <div
          className="row justify-content-center align-items-center"
          style={{
            height: "100%",
          }}
        >
          <div className="col-auto">
            <ProgressIndicator />
          </div>
        </div>
      </div>
    </div>
  );
};
