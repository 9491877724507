import { Component } from "react";
import "./Card.scss";

class Card extends Component {
  render() {
    return (
      <div className="row card" {...this.props}>
        {this.props.children}
      </div>
    );
  }
}

export default Card;
