import React, {Component} from 'react'
import './AddressSelectionModal.scss'

export default class AddressSelectionModal extends Component {
  render() {
    return (
      <div className='address-selection-modal' {...this.props}>
        <div className='content'>
          {this.props.children}
        </div>
      </div>
    )
  }
}
