export const SET_PICKUP = "SET_PICKUP"
export const SET_DELIVERY = "SET_DELIVERY"
export const SET_DATE = "SET_DATE"
export const SET_TIME = "SET_TIME"
export const SET_CONTACT = "SET_CONTACT"

export function setPickup(pickup) {
  return {
    type: SET_PICKUP,
    payload: pickup
  }
}
export function setDelivery(delivery) {
  return {
    type: SET_DELIVERY,
    payload: delivery
  }
}
export function setDate(date) {
  return {
    type: SET_DATE,
    date: date
  }
}
export function setTime(time) {
  return {
    type: SET_TIME,
    time: time
  }
}
export function setContact(contact) {
  return {
    type: SET_CONTACT,
    contact: contact
  }
}
