import mixpanel from "mixpanel-browser";
import { mixpanelToken } from "./constants";

mixpanel.init(mixpanelToken);

export default class Tracker {
  static track(eventName: string, properties?: any) {
    if (process.env.REACT_APP_CUSTOM_STAGE !== "production") return;
    mixpanel.track(eventName, properties);
  }
}
