import { Component } from "react";
import {
  Input,
  InputGroup,
  Button,
  Collapse,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";
import _ from "lodash";
import FloorElevatorPicker from "../FloorElevatorPicker/FloorElevatorPicker";
import AddressSuggestionList from "../AddressSuggestionList/AddressSuggestionList";
import MoveTypePicker from "../MoveTypePicker/MoveTypePicker";
import { Info } from "react-feather";
import "./AddressSuggestion.scss";
import { HereMapsClient } from "@silberistgold/orbit-kit";
import { hereApiKey } from "../../lib/constants";

const client = new HereMapsClient(hereApiKey);

export default class AddressSuggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_page: 0,
      suggestions: [],
      shouldShowNumberAlert: false,
      searchBarValue: this.props.value.location
        ? this.props.value.location.title
        : "",
      isLoadingSuggestions: false,
    };

    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddressClick = this.handleAddressClick.bind(this);
    this.handleMoveTypeClick = this.handleMoveTypeClick.bind(this);
    this.focusCaretToNumberPosition =
      this.focusCaretToNumberPosition.bind(this);
    this.fetchSuggestions = _.debounce(this.fetchSuggestions.bind(this), 200);
  }
  render() {
    const pages = [
      <AddressSuggestionList
        suggestions={this.state.suggestions}
        isLoading={this.state.isLoadingSuggestions}
        hasValidSearchQuery={this.state.searchBarValue.length > 2}
        onAddressClick={this.handleAddressClick}
      />,
      <MoveTypePicker
        noHelperText={this.props.noHelperText}
        twoHelperText={this.props.twoHelperText}
        threeHelperText={this.props.threeHelperText}
        selectedMoveType={this.props.value.move_type}
        onMoveTypeClick={this.handleMoveTypeClick}
      />,
      <FloorElevatorPicker
        story={this.props.value.story}
        onStoryChange={(story) => this.props.onValueChange({ story })}
        hasElevator={this.props.value.elevator}
        onElevatorChange={(elevator) => this.props.onValueChange({ elevator })}
        onFinish={this.props.toggle}
      />,
    ];
    return (
      <div className="address-suggestion d-flex flex-column">
        <div
          className="row no-gutters"
          style={{
            flexShrink: "0",
          }}
        >
          <div className="col-12">
            <InputGroup>
              <Input
                type="text"
                placeholder={`z.B. Bahnhofstr. 1, Berlin`}
                value={this.state.searchBarValue}
                onChange={this.handleInputChange}
                onFocus={() => {
                  this.setState({ active_page: 0 });
                }}
                innerRef={(input) => {
                  this.addressInput = input;
                }}
              />
              {this.state.active_page === 0 && (
                <>
                  <Info
                    color="#6d757d"
                    id="info-i"
                    style={{
                      position: "absolute",
                      top: "7px",
                      right: "82px",
                      zIndex: 999,
                    }}
                  />
                  <UncontrolledPopover
                    trigger="hover"
                    placement="bottom"
                    target="info-i"
                  >
                    <PopoverHeader>
                      Du kannst deine Adresse nicht finden?
                    </PopoverHeader>
                    <PopoverBody>
                      Kein Problem! Wähle einfach eine andere, nahegelegene
                      Adresse aus und vermerke die korrekte Adresse am Ende der
                      Buchung im Auftragskommentar.
                    </PopoverBody>
                  </UncontrolledPopover>
                </>
              )}
              <Button onClick={this.handleBackButtonClick}>Zurück</Button>
            </InputGroup>
            <Collapse isOpen={this.state.shouldShowNumberAlert}>
              <p className="alert-text">Bitte ergänze die Hausnummer</p>
            </Collapse>
          </div>
        </div>
        {pages[this.state.active_page]}
      </div>
    );
  }
  componentWillMount() {
    if (this.props.value.location) {
      this.fetchSuggestions(this.props.value.location.title);
    }
  }
  componentDidMount() {
    this.addressInput.focus();
  }
  focusCaretToNumberPosition(string) {
    const position = string.indexOf(",");
    const handle = setTimeout(() => {
      this.addressInput.focus();
      this.addressInput.setSelectionRange(position, position);
      clearTimeout(handle);
    }, 200);
  }
  handleBackButtonClick() {
    if (this.state.active_page === 0) {
      this.props.toggle();
    } else {
      this.setState({ active_page: this.state.active_page - 1 });
    }
  }
  handleAddressClick(address) {
    if (address.valid) {
      this.props.onValueChange({ location: address });
      this.setState({
        searchBarValue: address.title,
        active_page: 1,
        shouldShowNumberAlert: false,
      });
    } else {
      this.setState({
        searchBarValue: address.title,
        shouldShowNumberAlert: true,
      });
      this.focusCaretToNumberPosition(address.title);
    }
  }
  handleMoveTypeClick(moveType) {
    this.props.onValueChange({ move_type: moveType });
    if (moveType === 0) {
      this.props.toggle();
    } else {
      this.setState({ active_page: 2 });
    }
  }
  handleInputChange(event) {
    const newValue = event.target.value;
    this.setState({ searchBarValue: newValue, shouldShowNumberAlert: false });
    if (newValue.length > 2) {
      this.setState({ isLoadingSuggestions: true });
      this.fetchSuggestions(newValue);
    } else {
      this.setState({ suggestions: [] });
    }
  }
  fetchSuggestions(query) {
    this.setState({ isLoadingSuggestions: true });
    client
      .autosuggest({
        q: query,
        in: [
          `bbox:5.98865807458,47.3024876979,15.0169958839,54.983104153`,
          `countryCode:DEU`,
        ],
        lang: ["de"],
        limit: 20,
        show: ["streetInfo"],
      })
      .then((res) => {
        // filter res based on city plz
        const filtered = res.items
          // .filter((i) =>
          //   this.props.city.postalCodes.some((p) => i.title.includes(p))
          // )
          .map((r) =>
            Object.assign({}, r, { valid: r.resultType === "houseNumber" })
          );
        this.setState({ suggestions: filtered, isLoadingSuggestions: false });
      })
      .catch((err) => {
        alert(err);
        this.setState({ isLoadingSuggestions: false });
      });
  }
}
