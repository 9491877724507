import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setContact } from "../../actions/userinput_action";
import FillingWrapper from "../shared/FillingWrapper/FillingWrapper";
import Card from "../shared/Card/Card";
import ContactForm from "../ContactForm/ContactForm";
import { BottomNavigationBar } from "../shared/BottomNavigationBar/BottomNavigationBar";
import Tracker from "../../lib/Tracker";

const isValidPhoneNumber = (number) => {
  return /^\+[0-9]{5,15}$/.test(number);
};

const isValidEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

class ContactContainer extends Component {
  constructor(props) {
    super(props);
    this.isInputValid = this.isInputValid.bind(this);
    this.validate = this.validate.bind(this);
    this.handleContactValueChange = this.handleContactValueChange.bind(this);
  }
  render() {
    return (
      <FillingWrapper>
        <Card style={{ flexDirection: "column" }}>
          <div style={{ flexGrow: "1", overflowY: "auto" }}>
            <div className="row no-gutters justify-content-center flex-stretching">
              <div className="col-10">
                <ContactForm
                  contact={this.props.contact}
                  validation={this.validate()}
                  onValueChange={this.handleContactValueChange}
                  ref={(contactForm) => {
                    this.contactForm = contactForm;
                  }}
                />
              </div>
            </div>
          </div>

          <div style={{ flexGrow: "0" }}>
            <div className="row no-gutters justify-content-center flex-stretching">
              <div className="col-10 align-self-end">
                <BottomNavigationBar
                  shouldShowNextButton={true}
                  backTitle="Zurück zur Zusammenfassung"
                  isNextActive={this.isInputValid()}
                  nextTitle="Zahlungsmethode wählen"
                  nextRoute="/payment"
                />
              </div>
            </div>
          </div>
        </Card>
      </FillingWrapper>
    );
  }
  componentWillMount() {
    Tracker.track("contact");
  }
  validate() {
    const input = this.props.contact;
    const validationResult = {
      first_name: input.first_name.length > 0 ? true : null,
      last_name: input.last_name.length > 0 ? true : null,
      phone: input.phone.length > 0 ? isValidPhoneNumber(input.phone) : null,
      email: input.email.length > 0 ? isValidEmail(input.email) : null,
      has_accepted_legal: input.has_accepted_legal,
    };
    if (input.has_alt_from) {
      validationResult.alt_from_name =
        (input.alt_from_name || "").length > 0 ? true : null;
      validationResult.alt_from_phone =
        (input.alt_from_phone || "").length > 0
          ? isValidPhoneNumber(input.alt_from_phone)
          : null;
    }
    if (input.has_alt_to) {
      validationResult.alt_to_name = (input.alt_to_name || "").length > 0;
      validationResult.alt_to_phone =
        (input.alt_to_phone || "").length > 0
          ? isValidPhoneNumber(input.alt_to_phone)
          : null;
    }
    if (input.wants_invoice) {
      // companyName is optional
      validationResult.invoice_name = (input.invoice_name || "").length > 0;
      validationResult.invoice_street = (input.invoice_street || "").length > 0;
      validationResult.invoice_house_number =
        (input.invoice_house_number || "").length > 0;
      validationResult.invoice_zip = (input.invoice_zip || "").length > 0;
      validationResult.invoice_city = (input.invoice_city || "").length > 0;
    }
    return validationResult;
  }
  isInputValid() {
    const validated = this.validate();
    for (const key in validated) {
      if (!validated[key]) {
        return false;
      }
    }
    return true;
  }
  handleContactValueChange(contact) {
    this.props.setContact(contact);
  }
}

function mapStateToProps(state) {
  return { contact: state.user_input.contact };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setContact,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactContainer);
