import { Modal } from "reactstrap";
import styled from "styled-components";

export const MovematesModal = styled(Modal)`
  .modal-content {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border: none;
  }
`;
